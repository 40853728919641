export default [
	{
		title: "Sephora",
		image: require('../../../images/tgs/modal-images/sephora2.jpg'),
		modalImage: require('../../../images/tgs/sephora2.jpg'),
		content: [
			"To invite employees back to work at the brand’s SF headquarters, we jumped on board to create a clean and completely cool white-on-white welcome back kit, stocked with this super soft Alternative Apparel sweatshirt branded only with a whisper of Sephora.",
			"<b>IN A WORD:</b> Fresh.",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "Trove",
		image: require('../../../images/tgs/trove.jpg'),
		modalImage: require('../../../images/tgs/trove.jpg'),
		content: [
			"Trove transcends your typical subscription box. Each box focuses on a new city and delivers deliciously differenct ingredients, snacks, goodies–and live virtual experiences–from around the world, right to your doorstep. Trove-branded Herschel bags and beanies made great travel companions for their globe-trotting gourmands (aka employees).",
			"<b>IN A WORD:</b> Vamos!",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "Byte",
		image: require('../../../images/tgs/byte.jpg'),
		modalImage: require('../../../images/tgs/modal-images/byte.jpg'),
		content: [
			"It’s been an amazing year for Byte, a leader in the at-home teeth alignment category. To celebrate–and kick off summer with a perfect smile–we created a gift pack for each employee, including a portable speaker and a pair of chillers, all branded with Byte’s bitten “B” logo.",
			"<b>IN A WORD:</b> Party.",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "First Republic Bank",
		image: require('../../../images/tgs/firstrepublic.jpg'),
		modalImage: require('../../../images/tgs/modal-images/firstrepublic.jpg'),
		content: [
			"Clients come first at First Republic Bank. And they really feel the love when they open their gift of an absurdly soft, premium embroidered blanket from Marine Layer, along with a pair of extra cool, Eagle-branded S’well chillers.",
			"<b>IN A WORD:</b> Luxe.",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "Fisher Investments",
		image: require('../../../images/tgs/fisher.jpg'),
		modalImage: require('../../../images/tgs/fisher.jpg'),
		content: [
			"Fisher Investments wasn’t about to let quarantine prevent them from celebrating their employees’ hard work (and the company’s great quarter). The solution? Bring happy hour to their employees with this sleek FI-branded stainless growler and matching glasses.",
			"<b>IN A WORD:</b> Prost!",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "eBay Motors",
		image: require('../../../images/tgs/ebaymotors.jpg'),
		modalImage: require('../../../images/tgs/modal-images/ebaymotors.jpg'),
		content: [
			"New or used. Foreign or domestic. From cars and motorcycles to parts and accessories, eBay Motors has got you covered. And they know what’s up when it comes to impressing customers with these branded S’well chillers.",
			"<b>IN A WORD:</b> Surprise!",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "Sephora",
		image: require('../../../images/tgs/sephora.jpg'),
		modalImage: require('../../../images/tgs/sephora.jpg'),
		content: [
			"Sephora is ready for their spring awakening! To invite employees back to work at the brand’s SF headquarters, we jumped on board to create a clean and completely cool white-on-white welcome back kit, stocked with this modern classic Moleskine notebook, each branded only with a whisper of Sephora.",
			"<b>IN A WORD:</b> Fresh.",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "Amazon Fire TV",
		image: require('../../../images/tgs/firetv.jpg'),
		modalImage: require('../../../images/tgs/modal-images/firetv.jpg'),
		content: [
			"This is not your average water bottle. The team at Amazon Fire TV wanted something special. And not too over-the-top on the branding. We found the perfect match in Amazon’s fellow Seattle-based brand, Miir. The end result is a high-quality, reusable water bottle in a matte-black finish and just a splash of sleek branding.",
			"<b>IN A WORD:</b> Fire.",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "Accion Opportunity Fund",
		image: require('../../../images/tgs/accion.jpg'),
		modalImage: require('../../../images/tgs/accion.jpg'),
		content: [
			"The newly merged Accion Opportunity Fund is dedicated to revolutionizing financial services for underserved communities. Our mission was to help them create a daily reminder of this new union in an emotional, yet practical, way. We were inspired to set their colorful logo off against the crisp, clean white of a reusable water bottle.",
			"<b>IN A WORD:</b> Empowering.",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "Currency",
		image: require('../../../images/tgs/currency.jpg'),
		modalImage: require('../../../images/tgs/currency.jpg'),
		content: [
			"Currency is a SoCal based FinTech company–and an amazing partner. This time around, we worked together to create a holiday gift for their employees. It had to vibe with their quarterly outings at the beach and be sustainable. The solve? These slick, reusable coolers in matte black, the perfect backdrop to make their logo really pop.",
			"<b>IN A WORD:</b> Cheers!",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "AgDirect",
		image: require('../../../images/tgs/agdirect.jpg'),
		modalImage: require('../../../images/tgs/agdirect.jpg'),
		content: [
			"It’s gonna be a hot one, so AgDirect (providers of equipment loans for the ag industry) is showing their appreciation by creating a customized gift. These premium branded chillers from S’well keep cans cold and help their dealers survive another sweltering summer.",
			"<b>IN A WORD:</b> Cool.",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	},
	{
		title: "Currency",
		image: require('../../../images/tgs/currency2.jpg'),
		modalImage: require('../../../images/tgs/modal-images/currency2.jpg'),
		content: [
			"To keep everyone comfy and safe in quarantine over the holidays, we partnered with our favorite LA-based FinTech crew at Currency to surprise their employees with a high-quality hoodie (think built to last) and a reusable, Currency-branded mask.",
			"<b>IN A WORD:</b> Cozy.",
			"<a href='#' class='btn btn--secondary'>See More</a>"
		]
	}
]