export default [
	{
		title: "eBay",
		image: require('../../../images/our-work/ebay.jpg'),
		modalImage: require('../../../images/our-work/ebay2.jpg'),
		content: [
			"To celebrate eBay’s Seattle office, we crafted a capsule collection with cozy hoodies, eco-friendly beanies, and locally made MiiR mugs honoring Seattle’s coffee culture.",
			"IN A WORD: Bespoke"
		],
		caseStudySlug: "ebay-custom-capsule-collection"
	},
	{
		title: "Dropbox",
		image: require('../../../images/our-work/dropbox.png'),
		modalImage: require('../../../images/our-work/dropbox2.png'),
		content: [
			"We built a limited-edition merch store for Dropbox employees, featuring a sleek redemption page, countdown clock, and shipping to 180+ countries—all in just 3 weeks.",
			"IN A WORD: Global"
		],
		caseStudySlug: 'dropbox-pop-up-shop'
	},
	{
		title: "PrizePicks",
		image: require('../../../images/our-work/prizepicks.jpg'),
		modalImage: require('../../../images/our-work/prizepicks2.jpg'),
		content: [
			"For PrizePicks’ Taco Tuesday collection, we built a custom merch store and managed everything from inventory to fulfillment, bringing the fiesta to 2M+ members.",
			"IN A WORD: Fiesta"
		],
		caseStudySlug: 'prizepicks-merch-store'
	},
	{
		title: "Gem",
		image: require('../../../images/our-work/gem.jpg'),
		modalImage: require('../../../images/our-work/gem2.jpg'),
		content: [
			'Gem wanted fun, personalized gifts to boost sales. We delivered with a custom HubSpot page and curated treats like “Ted Lasso Biscuits,” driving sweeter connections and conversions.',
			"IN A WORD: Tasty"
		],
		caseStudySlug: "gem-integrated-marketing-campaign"
	},
	{
		title: "Assent",
		image: require('../../../images/our-work/assent.jpg'),
		modalImage: require('../../../images/our-work/assent2.jpg'),
		content: [
			"To launch Assent's new brand, we curated custom gift boxes that showcased their company values and how those connect to their swag choices. Because like we always say, what you put your brand on maters!",
			"IN A WORD: Evoke"
		],
		caseStudySlug: 'assent-brand-launch'
	},
	{
		title: "Fastly",
		image: require('../../../images/our-work/fastly.jpg'),
		modalImage: require('../../../images/our-work/fastly2.jpg'),
		content: [
			"Fastly needed sustainable gifting at scale. We curated eco-friendly kits with custom branding, reflecting their green values while making connections seamless.",
			"IN A WORD: Earth-friendly"
		],
		caseStudySlug: "fastly-customer-gifting-site"
	},
	{
		title: "Slack",
		image: require('../../../images/our-work/slack.png'),
		// modalImage: require('../../../images/our-work/slack2.jpg'),
		content: [
			"Partnering with ERG teams to celebrate their uniqueness is our jam. For women@slack, we refreshed their logo in brand colors and embroidered it on a sleek black crewneck.",
			"IN A WORD: Bold"
		]
	},
	{
		title: "Webflow",
		image: require('../../../images/our-work/webflow.jpg'),
		modalImage: require('../../../images/our-work/webflow2.jpg'),
		content: [
			"From sales kickoffs to memorable offsites, we powered Webflow’s team spirit with custom swag kits, locally sourced treats, and branded gear—keeping teams motivated and celebrating every success.",
			"IN A WORD: Together"
		],
		caseStudySlug: "webflow-team-events-swag"
	},
	{
		title: "Tune",
		image: require('../../../images/our-work/tune.jpg'),
		// modalImage: require('../../../images/our-work/tune2.jpg'),
		content: [
			"When it comes to making a statement, sometimes less is more. Especially when it comes to these showstopping Karst stone notebooks made without trees and water and bamboo pens. Sustainable, meet style.",
			"IN A WORD: Modern"
		]
	},
	{
		title: "TAIT",
		image: require('../../../images/our-work/tait.jpg'),
		modalImage: require('../../../images/our-work/tait2.jpg'),
		content: [
			"TAIT’s custom swag shop delivered meaningful gifts to employees and clients worldwide. Recipients could even donate to ‘Diversify the Stage’ instead of receiving a gift.",
			"IN A WORD: Thankful"
		]
	},
	{
		title: "OceanX",
		image: require('../../../images/our-work/oceanx.jpg'),
		modalImage: require('../../../images/our-work/oceanx2.jpg'),
		content: [
			"We had a blast curating thoughtful, ocean-friendly gifts for our friends at OceanX, such as this bag made from recycled sails and custom boxes. Explore what matters!",
			"IN A WORD: Adventure"
		]
	},
	{
		title: "University of Oregon",
		image: require('../../../images/our-work/oregon.jpg'),
		modalImage: require('../../../images/our-work/oregon2.jpg'),
		content: [
			"We teamed up with the University of Oregon to generate buzz and support with alumni and donors at key events and created a sought-after merch store with branded gear exclusively for former D1 athletes.",
			"IN A WORD: Go Ducks!"
		]
	},
	{
		title: "Change.org",
		image: require('../../../images/our-work/change.png'),
		// modalImage: require('../../../images/our-work/change2.png'),
		content: [
			"On Change.org, people connect across geographic and cultural borders to support causes they care about. We love supporting their employee gifts and new hire experience!",
			"IN A WORD: Empower"
		]
	},
	{
		title: "Snap",
		image: require('../../../images/our-work/snap.png'),
		modalImage: require('../../../images/our-work/snap2.jpg'),
		content: [
			"We loved outfitting Snap's interns in head-to-toe merch that would turn heads. We loved the embroidered logo on the matching hoodie and sweatshirt set, tote, shirt, and hat.",
			"IN A WORD: Vibe check"
		]
	},
	{
		title: "Go1",
		image: require('../../../images/our-work/go1.png'),
		// modalImage: require('../../../images/our-work/ebay2.jpg'),
		content: [
			"Oh baby! Go1 makes new parents feel the love with on-demand gifting. This e-learning platform ensures every gift ships on time, every time.",
			"IN A WORD: Thoughtful"
		]
	},
	{
		title: "Hyatt",
		image: require('../../../images/our-work/hyatt.png'),
		modalImage: require('../../../images/our-work/hyatt2.jpg'),
		content: [
			"Hyatt swooned over this darling wine country picnic set. The perfect gift to celebrate your team and inspire them to unwind this Spring or Summer.",
			"IN A WORD: Delightful"
		]
	},
	{
		title: "Courier",
		image: require('../../../images/our-work/courier.jpg'),
		modalImage: require('../../../images/our-work/courier2.jpg'),
		content: [
			"We partnered with Courier's HR team to create a one-of-a kind new hire kit. We had a lot of fun sketching and custom designing a plushie version of their messenger bird to life.",
			"IN A WORD: Swift"
		]
	},
	{
		title: "Thankful",
		image: require('../../../images/our-work/thankful.jpg'),
		modalImage: require('../../../images/our-work/thankful2.jpg'),
		content: [
			"We obsess over finding unique ways to bring your brand to life, from curation to design to the final details. We can't get enough of these fabric tags that add that extra something to thankful's fun and bold brand.",
			"IN A WORD: Style"
		]
	},
	{
		title: "Byte",
		image: require('../../../images/our-work/byte.jpg'),
		modalImage: require('../../../images/our-work/byte2.jpg'),
		content: [
			"To celebrate a standout year, Byte employees got summer-ready with a custom gift pack featuring portable speakers and chillers—all stamped with their iconic “bitten B” logo.",
			"IN A WORD: Fresh"
		]
	},
	{
		title: "Sephora",
		image: require('../../../images/our-work/sephora2.jpg'),
		modalImage: require('../../../images/our-work/sephora.jpg'),
		content: [
			"We welcomed employees back to SF HQ with sleek kits featuring Alternative Apparel sweatshirts and subtly branded Moleskine notebooks—a perfect whisper of Sephora.",
			"IN A WORD: Sophisticated"
		]
	},
	{
		title: "Asana",
		image: require('../../../images/our-work/asana.png'),
		// modalImage: require('../../../images/our-work/ebay2.jpg'),
		content: [
			"Asana is best-in-class when it comes to enabling teams to work together effortlessly. So, it's no surprise we created a gift that orchestrates a calming effect and positive vibes for their team.",
			"IN A WORD: Zen"
		]
	},
	{
		title: "Mercyhealth",
		image: require('../../../images/our-work/mercy.jpg'),
		// modalImage: require('../../../images/our-work/ebay2.jpg'),
		content: [
			"Pair a Hydroflask wide-mouth water bottle with a custom sticker sheet showcasing your company values, and you’ve got the ultimate new nurse welcome kit. That's swag with a purpose.",
			"IN A WORD: Sticky"
		]
	},
	{
		title: "Brighthire",
		image: require('../../../images/our-work/brighthire.jpg'),
		modalImage: require('../../../images/our-work/brighthire2.jpg'),
		content: [
			"Welcoming your new hires in style is the perfect way to make their first day unforgettable. Partnering with Brighthire's onboarding team, we helped roll out the red carpet for their newest team members.",
			"IN A WORD: Ignite"
		]
	},
	{
		title: "Levi Strauss",
		image: require('../../../images/our-work/levi.png'),
		modalImage: require('../../../images/our-work/levi2.png'),
		content: [
			"Honored to support Levi’s BIPOC Leadership Cohort, we curated a Black-Owned Elemental Gift Set featuring Women-Owned Aesthete Tea to celebrate innovation and creativity.",
			"ONE WORD: Rise"
		]
	},
	{
		title: "The New Happy",
		image: require('../../../images/our-work/thenewhappy.png'),
		modalImage: require('../../../images/our-work/thenewhappy2.jpg'),
		content: [
			"When The New Happy talks happiness, notes are a must! We designed custom notebooks for a LinkedIn Wellness Event, giving attendees the perfect place to capture every joyful insight.",
			"IN A WORD: Joyful"
		]
	},
	{
		title: "Chronicle Books",
		image: require('../../../images/our-work/chroniclebooks.jpg'),
		modalImage: require('../../../images/our-work/chroniclebooks2.png'),
		// modalImage: require('../../../images/our-work/ebay2.jpg'),
		content: [
			"Two local San Francisco favorites: Chronicle Books and Fellow. The delicate glasses logo is etched to make a bold statement on this beautifully designed matte grey mug. ⁠Like a good book, we can't put this one down.",
			"IN A WORD: Harmony"
		]
	},
	{
		title: "Google",
		image: require('../../../images/our-work/google.png'),
		// modalImage: require('../../../images/our-work/ebay2.jpg'),
		content: [
			"Special Delivery! We loved curating these holiday gifts for Google. A perfect trio for the team to reflect, relax, and recharge. If only everyone could smell this amazing candle and feel this insanely soft blanket.",
			"IN A WORD: Cozy"
		]
	},
	{
		title: "Peloton",
		image: require('../../../images/our-work/peloton.png'),
		// modalImage: require('../../../images/our-work/ebay2.jpg'),
		content: [
			"Together we go far, and Team Peloton leads the pack. We outfitted their tech teams with jackets, vests, and hoodies featuring embroidered logos and customized team names.",
			"IN A WORD: Personalized"
		]
	},
	{
		title: "Untamed Planet",
		image: require('../../../images/our-work/untamedplanet.jpg'),
		// modalImage: require('../../../images/our-work/ebay2.jpg'),
		content: [
			"We take pride in partnering with B Corp brands like Rumpl, making them the perfect match for Untamed Planet and their mission to inspire and protect the natural world.",
			"IN A WORD: Purposeful"
		]
	},
	{
		title: "LinkedIn Asian Alliance",
		image: require('../../../images/our-work/laa.jpg'),
		modalImage: require('../../../images/our-work/laa2.jpg'),
		content: [
			"We teamed up with LinkedIn’s Asian Alliance ERG to celebrate their redesigned logo with custom water bottles, jackets, and shirts. Shipped to eight countries, this global effort embodied creativity, culture, and connection.",
			"IN A WORD: Inclusion"
		]
	}
	// {
	// 	title: "",
	// 	image: require('../../../images/our-work/ebay.jpg'),
	// 	modalImage: require('../../../images/our-work/ebay2.jpg'),
	// 	content: [
	// 	]
	// }
]