<template>
	<div class="carousel-wrap">
		<div class="carousel-wrap__carousel">
			<div id="heroCarousel">
				<div v-for="image in images">
					<div class="slide-inner">
						<img :src="image.src" :alt="image.alt" />
					</div>
				</div>
			</div>
		</div>
		<nav class="carousel-wrap__nav">
			<button @click='heroSiema.prev()' aria-label="Previous image">&lt;</button>
			<button @click='heroSiema.next()' aria-label="Next image">&gt;</button>
		</nav>
	</div>
</template>

<script>
	import Siema from 'siema';

	import blanketImage from '../../images/home/hero/blanket.webp';
	import bottlesImage from '../../images/home/hero/bottles.webp';
	import canvasVentures from '../../images/home/hero/canvas.png';
	import cotopaxi from '../../images/home/hero/cotopaxi.jpg';
	import mercyHealth from '../../images/home/hero/mercy.png';

	export default {
		name: 'CarouselHero',
		data () {
			return {
				images: [
					{
						src: bottlesImage,
						alt: "Swagger custom branded S'well Traveler Bottles printed with Swagger logo",
					}, 
					// {
					// 	src: blanketImage,
					// 	alt: 'Marine Layer custom branded Corbet Blanket Embroidered With Contrary Logo.',
					// }, 
					{
						src: mercyHealth,
						alt: 'Custom branded Hydroflask for Mercy Health in Custom Branded Box designed and produced by Swagger.',
					}, {
						src: cotopaxi,
						alt: 'Customized Cotopaxi Backpack for Forethought by Swagger.',
					}, {
						src: canvasVentures,
						alt: 'Marine Layer custom branded Corbet Quarter Zip Fellow Tumbler Custom Box for Canvas Ventures.',
					}
				],
				heroSiema: {
					// these get overwritten when siema is initialized in mounted() below
					next: () => console.log('next'),
					prev: () => console.log('prev')
				}
			}
		},
		mounted () {
			this.heroSiema = new Siema({
				selector: '#heroCarousel',
				perPage: 1,
				loop: true
			});

			// "autoplay"
			setInterval(() => this.heroSiema.next(), 3000);
		}
	};
</script>
