<script>
	// import ImageGrid from '../ImageGrid.vue'
	import images from './images-short';

	export default {
		name: 'OurWorkImageWrapper',
		// components:{ ImageGrid },
		data () {
			return {
				images: images
			}
		}
	};
</script>

<template>
	<div class="grid2">
		<div class="grid2__item" v-for="image in images">
			<div class="image">
				<img :src="image.image">
				<div class="image__overlay" :style="`background-image: url(${image.modalImage})`"></div>
			</div>
			<div class="copy">
				<h2>{{image.title}}</h2>
				<p v-for="string in image.content" v-html="string"></p>
			</div>
		</div>
	</div>
</template>

<style type="text/css">
	/* @todo - standardize with main styles (blog, lookbooks index)	*/
	.grid2 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		/* padding: 0 5vw 5em; */
		padding: 0 2em;
	}

	.grid2__item {
		flex-basis: calc(100% - 1em);

		background: $colorBrandCream;
		border-radius: $uiBorderRadius;
		margin: 0.5em;
		padding: 1em;

		/* &:nth-child(4n + 1) {
			background-color: $colorBrandRustLighter;
		}
		&:nth-child(4n + 2) {
			background-color: $colorBrandCream;
		}
		&:nth-child(4n + 3) {
			background-color: $colorBrandBlueLighter;
		}
		&:nth-child(4n + 4) {
			background-color: $colorBrandGreenLight;
		} */
	}

	.grid2__item img {
		display: block;
		width: 100%;
	}

	@media screen and (min-width: 751px) {
		.grid2__item {
			flex-basis: calc(50% - 1em);
		}
	}

	@media screen and (min-width: 1101px) {
		.grid2__item {
			flex-basis: calc(33.333% - 1em);
		}
	}

	.grid2__item .image {
		position: relative;
		border-radius: 20px;
		display: block;
		margin-bottom: 1em;
		overflow: hidden;

		img {
			z-index: 1;
		}

		.image__overlay {
			position: absolute;
			inset: 0;
			z-index: 2;

			background-size: cover;
			background-position: center;
			opacity: 0;
			transition: opacity 0.15s ease-out;
		}

		&:hover {
			.image__overlay {
				opacity: 1;
			}
		}
	}

</style>